import logo from "../logo.svg";
import Main from "./Main";
import "../styles/SiteActive.css";

function SiteActive(props) {
    const siteActive = props.siteActive;

    if(siteActive) {
        return <Main/>
    }
    else {
        return <div className='SiteActive-Container'>
            <img src={logo} alt="logo" className='Logo'/>
            <div className='ComingSoon'>
                <p>Coming Soon</p>
            </div>
        </div>
    }
}

export default SiteActive
