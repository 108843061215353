import { createTheme } from '@mui/material/styles';

const Theme = createTheme({
  palette: {
    primary: {
      light: '#757ce8',
      main: '#3f50b5',
      dark: '#002884',
      contrastText: '#fff',
    },
    secondary: {
      light: '#f6f7ec',
      main: '#f6f7ec',
      dark: '#f6f7ec',
      contrastText: '#000',
    },
  },
  spacing: 4, 
  shape: {
    borderRadius: 8,  // Example for border radius in styled components
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
    },
  },
});

export default Theme;