import React, { Component } from 'react';
import Home from './Home';
import RSVP from './RSVP';
import Recommendations from './Recommendations';
import IvoryButton from "./IvoryButton";
import Details from "./Details";
import Clothes from "./Clothes";

const buttonConfigs = [
    { label: 'Details', component: 'details', bottom: '40%' },
    { label: 'RSVP', component: 'rsvp', bottom: '30%' },
    { label: 'What to Wear', component: 'clothes', bottom: '20%' },
    { label: 'London', component: 'recommendations', bottom: '10%' }
];

class Main extends Component {
    constructor(props) {
        super(props);
        this.state = {
            currentComponent: 'home',
        };
        this.setComponent = this.setComponent.bind(this);
    }

    setComponent(componentName) {
        this.setState({ currentComponent: componentName });
    }

    getComponent() {
        const { currentComponent } = this.state;
        switch (currentComponent) {
            case 'home':
                return <Home />;
            case 'rsvp':
                return <RSVP />;
            case 'recommendations':
                return <Recommendations />;
            case 'details':
                return <Details />;
            case 'clothes':
                return <Clothes />;
            default:
                return <Home />;
        }
    }

    render() {
        const { currentComponent } = this.state;

        return (
            <div className="main-container">
                {currentComponent === "home" ? (
                    <div>
                        <div className='HomeImage'>
                            {buttonConfigs.map(({ label, component, bottom }) => (
                                <IvoryButton
                                    key={component}
                                    variant="outlined"
                                    sx={{ position: "absolute", left: "80%", bottom }}
                                    onClick={() => this.setComponent(component)}
                                >
                                    {label}
                                </IvoryButton>
                            ))}
                        </div>
                    </div>
                ) : (
                    <div className="button-container">
                        <IvoryButton
                            key='home'
                            variant="outlined"
                            onClick={() => this.setComponent('home')}
                        >
                            Home
                        </IvoryButton>
                        {buttonConfigs.map(({ label, component }) => (
                            <IvoryButton
                                key={component}
                                variant="outlined"
                                onClick={() => this.setComponent(component)}
                            >
                                {label}
                            </IvoryButton>
                        ))}
                    </div>
                )}
                <div className="component-container">
                    {this.getComponent()}
                </div>
            </div>
        );
    }
}

export default Main;
