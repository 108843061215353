export const recommendationData = [
    {
        link: "https://www.madeleinescreperie.com/",
        imgSrc: "https://www.madeleinescreperie.com/wp-content/uploads/sb-instagram-feed-images/345079101_638829784766227_4076613541010930901_nfull.jpg",
        title: "Madeline's",
        description: "Near to Blackheath station is one of the best little places to get a coffee and a gallette. Perfect for a late breakfast on a Sunday before walking around the farmers market."
    },
    {
        link: "https://ornc.org/explore-whats-here/painted-hall/",
        imgSrc: "https://ornc.org/wp-content/uploads/2022/11/PH-001-copy-low-res-640x467.jpg",
        title: "The Painted Hall",
        description: "Near to the Cutty Sark in one of our favourite areas of London is the Painted Hall. It is a gorgeous building and they do regular free tours where they explain the importance of some of the artworks."
    },
    {
        link: "https://www.happinessforgets.com/",
        imgSrc: "https://images.squarespace-cdn.com/content/v1/5a11be1280bd5e96f8b8096a/1585772806196-HVTFIRIXXX7Y9U5CXQKN/Happiness+Forgets+Interiors+2018-20.jpg",
        title: "Happiness Forgets",
        description: "The location of our engagement photos and a wonderful little hidden gem. Happiness Forgets is a basement cocktail bar that presents drinks far beyond the usual hotel fair."
    },
    {
        link: "https://www.camdenpassageislington.co.uk/",
        imgSrc: "https://www.camdenpassageislington.co.uk/images/cp_property_photos/general_photos/img_7732.jpg",
        title: "Camden Passage",
        description: "A recent discovery of ours is this nice little area near to Angel station. It is a few narrow streets of vintage shops, antique shops, art supplies and a number of other things."
    },
    {
        link: "https://www.coventgarden.london/visit/seven-dials/",
        imgSrc: "https://static.standard.co.uk/s3fs-public/thumbnails/image/2014/04/24/15/7%20dials.jpg?width=1200",
        title: "Seven Dials",
        description: "If you are in central London and want to wander around for a while then you can do an awful lot worse than exploring Seven Dials. These seven streets have a range of different shops and eating places to wander into."
    },
    {
        link: "https://www.soane.org/",
        imgSrc: "https://britishtours-prod.b-cdn.net/cms-files/view/soane-museum_5ecfa34c8d7fb.jpg?v=1723087232",
        title: "The John Soane Museum",
        description: "If you have a taste for the macabre then this is a must visit. The museum is the collection of a famous architect with an obsession who left his house and artifacts to the public in his will. If you enjoy something spooky then they also offer night-time viewings by candle-light!"
    },
    {
        link: "https://princecharlescinema.com/",
        imgSrc: "https://media.timeout.com/images/105293103/image.jpg",
        title: "The Prince Charles",
        description: "A cinema might be a bit of an odd recommendation, but The Prince Charles is one of the greats. They show an eclectic mix of old and new movies, often projecting from original 35 and 70mm prints rather than digital."
    },
    {
        link: "https://rotiking.com/",
        imgSrc: "https://www.hot-dinners.com/images/stories/blog/2023/rotikingwaterloo.jpg",
        title: "Roti King",
        description: "This is one of those places that has grown massively in the time we have been in London. Formerly a tiny basement where those in the know would queue for a chance at a seat, now they have multiple branches dishing out delicious malaysian curry and warm rotis."
    },
    {
        link: "https://www.beerandburgerstore.com/",
        imgSrc: "https://www.kingscross.co.uk/media/Beer-Burger-06_2000-1920x1080.jpg?cache=1712579371",
        title: "Beer & Burger",
        description: "If you don't like beer or burgers then this is not the place for you. If you do like them then B&B do them about as well as anywhere else in the city."
    },
    {
        link: "https://www.vasanthavilas.co.uk/",
        imgSrc: "https://www.vasanthavilas.co.uk/wp-content/uploads/2024/07/vasantha-vilas-east-ham.jpg",
        title: "Vasantha Vilas",
        description: "Hopefully no one reading this is shocked to learn that Siva is Tamil. So it follows that we spend a fair amount of time in East Ham, London's South Indian neighbourhood. Of the many restaurants we have tried there Vasantha Vilas remains our go to."
    },
    {
        link: "https://www.britishmuseum.org/visit",
        imgSrc: "https://vocaleyes.co.uk/wp-content/uploads/2016/11/British_Museum_Dome-1400x931.jpg",
        title: "The British Museum",
        description: "I considered not mentioning The British Museum because surely everyone has heard of it, but this list would be incomplete without it. In short, if you haven't been then you should go, just don't expect to see the whole thing in one visit. I have been many times and I am still finding new things!"
    },
    {
        link: "https://www.pizzaunion.com/",
        imgSrc: "https://mini-adventures.com/wp-content/uploads/2015/10/pizza-union-blog-review-spitalfields.jpg",
        title: "Pizza Union",
        description: "I am sure that some of our visitors are going to be taken by surprise with how expensive eating out in London can be. The trick is to know where you can get good food for cheap. Pizza Union may not make the best pizzas you can buy in London, but their pizzas are far nicer than they have any right to be for the price!"
    },
    {
        link: "https://chinatown.co.uk/en/",
        imgSrc: "https://xyuandbeyond.com/wp-content/uploads/chinese-new-year.jpg",
        title: "Chinatown",
        description: "One of the best things about London is how multi-cultural it is, nothing makes that clearer than the bustling streets of London's chinatown. Wander around, buy a dumpling, stop by somewhere for duck pancakes or just enjoy wandering around an area that is very different to it's surroundings."
    },
    {
        link: "https://www.stmartin-in-the-fields.org/visit/cafe-in-the-crypt/",
        imgSrc: "https://static.toiimg.com/thumb/53185587/Cafe_in_the_Crypt.jpg?width=1200&height=900",
        title: "Cafe in the Crypt",
        description: "If you have been wandering around Trafalgar Square and need a sit down and a quick drink then this is just the spot. It is also a nice little piece of London history in it's own right as the cafe is situated (as the name implies) in the crypt of St Martin in the Fields church."
    },
    {
        link: "https://www.westend.com/",
        imgSrc: "https://dynamic-media-cdn.tripadvisor.com/media/photo-o/2b/0b/c5/81/caption.jpg?w=1200&h=700&s=1&cx=2808&cy=1872&chk=v1_ee399edc41a2e437753b",
        title: "The West End",
        description: "I struggled to write an entry for this as I kept thinking of individual shows to mention. I decided the easiest thing was to just recommend the concept of seeing a show! There are a lot of great musicals on in London, sometimes you can get cheaper tickets, sometimes not. Reach out to us and we can try and help!"
    },
    {
        link: "https://oliversjazzbar.com/",
        imgSrc: "https://cdn.londonandpartners.com/asset/olivers-jazz-bar_photo-image-courtesy-of-olivers-jazz-bar_af809ec34f9420e9433da98398805139.jpg",
        title: "Oliver's Jazz Bar",
        description: "Formerly our local, Oliver's is a great spot to go and see some live music. Grab a table with a view of the stage and settle down for a few hours after a busy day of travelling around London. They do have a cover charge for entry which I think has to be paid in cash so be ready for that."
    },
    {
        link: "https://www.sonorataqueria.com/",
        imgSrc: "https://i2-prod.mylondon.news/incoming/article27630143.ece/ALTERNATES/s615b/0_IMG_0494.jpg",
        title: "Sonora Taqueria",
        description: "A tiny place that you will easily recognise from the continual queues for food! We found this one by accident (while scouting out wedding locations actually) but in our opinion they do the best tacos in London. Just make sure you check the opening times, they are only open a few days a week."
    },
    {
        link: "https://www.aintnothinbut.co.uk/",
        imgSrc: "https://scontent-lhr6-1.xx.fbcdn.net/v/t39.30808-6/400781925_642839144677446_1655053912833715353_n.jpg?_nc_cat=110&ccb=1-7&_nc_sid=cc71e4&_nc_ohc=WfH9Z8iEiV8Q7kNvgHvUymS&_nc_ht=scontent-lhr6-1.xx&oh=00_AYC23lJnxwBamTggFIbPILflI0o7xcYff-SMcyC4x8gk_Q&oe=66E9C85F",
        title: "Ain't Nothin' But...",
        description: "Another tiny music venue, this time for the blues. Again, great music, a fun crowd, and a great place to grab a seat and lose yourself in the music for a couple of hours. It is in central soho so take a walk down famous Carnaby Street first to really get the right music vibes."
    },
    {
        link: "https://galvinrestaurants.com/michelin-french-restaurant-city-london-galvin-la-chapelle/",
        imgSrc: "https://ik.imagekit.io/sbj8bzmjnl4/galvin/wp-content/themes/pgwd22/img/json/top-best-restaurant-french-michelin-city-of-london-bishopsgate-dining-experience-galvin-la-chapelle.jpg",
        title: "Galvin La Chapelle",
        description: "This one may not be for everyone, but if you want to try the more high-end side of London cuisine then this Michelin Star restaurant is a great pick. We went here for Siva's 30th birthday and the food was incredible! The prices were higher than I would normally spend for a single meal but nowhere near as bad as I had feared."
    },
    {
        link: "https://www.wherethepancakesare.com/",
        imgSrc: "https://www.wherethepancakesare.com/wp-content/uploads/sites/94/2022/12/WHERE_THE_PANCAKES_ARE_31_08_221261-1030x687.jpg",
        title: "Where the Pancakes Are",
        description: "Another great breakfast place, fantastic pancakes, fantastic toppings, overall fantastic. If you like a sweet coffee then try the Spanish Latte."
    },
    {
        link: "https://mercatometropolitano.com/mercato-mayfair/",
        imgSrc: "https://offloadmedia.feverup.com/secretldn.com/wp-content/uploads/2021/08/25122706/61ae64471a8574f50ce9eff2_altar-mercato-mayfair-p-2000-1024x683.jpg",
        title: "Mercato Mayfair",
        description: "There are a lot of great food halls in London (where you can buy meals from different stalls and all sit together at communal tables), but this one really stands out from its gorgeous location. In my opinion it's worth wandering around even if you aren't hungry."
    }
];
