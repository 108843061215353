import './App.css';
import Authentication from "./Components/Authentication";
import {BrowserRouter, Route, Routes} from "react-router-dom";

function App(props) {
    const siteActive = props.siteActive;
    return (
      <BrowserRouter>
        <Routes>
          <Route path="/" />
        </Routes>
        <div className="LoginPage">
          <LoginPage siteActive={siteActive}/>
        </div>
      </BrowserRouter>
  );
}

function LoginPage(props){
  const siteActive = props.siteActive;
  return <Authentication siteActive={siteActive}/>
}

export default App;
