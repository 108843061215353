import styled from "@emotion/styled";
import LogoutIcon from '@mui/icons-material/Logout'; // Importing LogoutIcon


const LogoutButton = styled(LogoutIcon)(({ theme }) => ({
    color: '#f6f7ec',
    fontSize: '2.5vw',
    borderColor: '#f6f7ec',
    '&:hover': {
      backgroundColor: '#f6f7ec',
      color: '#000000',
      opacity: 0.75
    },
  }));

  export default LogoutButton;